import React from 'react'

/* Styles */
import ContentPageWrapperStyles from './ContentPageWrapper.module.scss'

const ContentPageWrapper = ({
	children,
	selfContained,
}: {
	children: React.ReactNode
	selfContained?: boolean
}): React.ReactElement => {
	if (selfContained) {
		return <>{children}</>
	}

	return (
		<section className={ContentPageWrapperStyles['wrapper']}>
			{children}
		</section>
	)
}

export default ContentPageWrapper
